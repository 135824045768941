import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "../../../styles/pagination.css";
import ArrowSquare from "../../../images/firedancer/arrow.inline.svg";


const projectBox = {
  textAlign: "center",
  // background: "#091A3D",
  border: "1px solid #4B608D",
  position: "relative",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  minHeight: "105px",
  display: "flex",
  flexDirection: "column",
  // p: 1.5,
  alignItems: "center",
  justifyContent: "center",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#091A3D",
    border: "1px solid #4B608D",
    left: "10px",
    top: "-10px",
    zIndex: -1,
    transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  "& span": {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#091A3D",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    opacity: "0",
    transition: ".3s ease-in-out",
  },
  "&:hover span": {
    opacity: 1,
  },
};

const DeepDiveArticle = () => {
  return (
    <>
      <Box
        component="div"
      >
        <Box
          component="div"
          sx={{
            position: "relative",
            maxWidth: 1082,
            mx: "auto",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            mt: { xs: 2, md: 4, },
            mb: { xs: 4, md: 4 },
          }}
        >

          <Box
            component="div"
            sx={{
              mb: 4,
              alignSelf: { xs: "initial", sm: "end" },
              px: 0,
            }}
          >

            <Box component="div" sx={{ position: "absolute", top: 30, left: 30, zIndex: 2 }}>
              <Typography sx={{ mb: 1 }}>
                June 1, 2024
              </Typography>
              <Typography variant="h2" sx={{ fontSize: 32, mb: 1 }}>
                What is<br />Firedancer?<br />
              </Typography>
              <Typography sx={{ fontWeight: 600 }}>
                by HELIUS
              </Typography>
            </Box>
            <Link
              component="a"
              href="https://www.helius.dev/blog/what-is-firedancer"
              target="_blank"
              rel="noreferrer"
              sx={projectBox}
            >

              <StaticImage
                src="https://cdn.prod.website-files.com/641ba798c17bb180d832b666/6567881b221d6d7a2a29b444_FD_Blogbanner.jpg"
                placeholder="blurred"
                alt="Fortune"
                loading="lazy"
                style={{ objectFit: "cover", marginBottom: 11 }}
              />


              <Box component="span">
                <ArrowSquare />
                .Read more
              </Box>
            </Link>
          </Box>

        </Box>

      </Box >
    </>
  );
};

export default DeepDiveArticle;
